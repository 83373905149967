import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import bookingInfo from "./bookingInfo";
import usersInfo from './usersInfo';
import manualKYC from './manualKYC';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        bookingInfo,
        usersInfo,
        manualKYC,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
