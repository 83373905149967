import api from './api/api';

export function login(data) {
  return api.post("/signIn", data);
}

export function forgotPassword(data) {
  return api.put(`/forgot-password`, data);
}

export function verifyOTP(data) {
  return api.post(`/verify-otp-forgot-password`, data);
}

export function resetPassword(data) {
  return api.put(`/password`, data);
}